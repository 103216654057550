import React from 'react'
import {Icon} from 'antd'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'
const NotFoundPage = () => (
  <Layout>
     <SEO title=":(" description="404 page."/>
    <div className="not-found">
        <Icon type="api" style={{ fontSize: '100px'}}/>
        <h3>Page not found. Go back to <Link to="/">inbox</Link></h3>
    </div>
  </Layout>
)

export default NotFoundPage;
